









































import { Component, Model, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class ProjectSidebar extends Vue {
  @Prop({ required: true }) visible!: boolean;

  @Model('input') readonly projectName!: string;

  async mounted () {
    await this.$store.dispatch('loadProjects')
  }

  get projects () {
    return this.$store.state.projects
  }

  deleteProject (project) {
    this.$store.dispatch('deleteProject', project)
  }
}
