
































import { Vue, Component, Prop } from 'vue-property-decorator'
import { TauxObject } from '../types'

@Component
export default class Taux extends Vue {
  @Prop() value!:TauxObject

  content:TauxObject = this.value
  open:boolean = false;
  description = {
    urssafBase: 'Base URSSAF',
    jeContrib: "Part JE : Total des taux des cotisations indexées sur l'assiette de cotisation",
    jepay: 'Part JE : Total des taux des cotisations indexées sur la rémunération brute',
    consultantContrib: "Part Etudiant : Total des taux des cotisations indexées sur l'assiette de cotisation",
    consultantPay: 'Part Etudiant : Total des taux des cotisations indexées sur la rémunération brute'
  }

  toggle () {
    this.open = !this.open
  }

  handleInput (e) {
    this.$emit('input', this.content)
  }
}
