








































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

// import DistributionChart from './DistributionChart'
import DistributionChart from '../chart/ReadingChart.vue'
import { PhaseObject, TauxObject } from '../types'
import Phase from './Phase.vue'
import Consultants from './Consultant.vue'
import Frais from './Frais.vue'
import ProjetsSidebar from './ProjetsSidebar.vue'
import MargesDetails from './MargesDetails.vue'
import { round, utf8ToB64, b64ToUtf8 } from '../utils'

@Component({
  components: { Phase, DistributionChart, Consultants, MargesDetails, Frais, ProjetsSidebar }
})
export default class JehMaker extends Vue {
  @Prop({ required: true }) taux!: TauxObject;

  // Data
  projectName:string = '';
  phases: PhaseObject[] = []
  totalPrice:number = 0
  averageJeh:number = 0
  averageMargin:number = 0
  totalNbJeh:number = 0
  totalPay:number = 0
  totalUrssafJe:number = 0
  averageMarginJe:number = 0
  totalUrssafConsultant:number = 0
  totalNetConsultant:number = 0
  averagePcConsultant:number = 0
  fee:number = 100
  opMargin:number = 0
  id:number|null = null

  consultants: string[] = []

  openExportPopup:boolean = false;
  isOpenProjectSidebar:boolean = false;
  url:string= '';
  urlImport:string= '';

  get chartData ():Object {
    return {
      labels: ['JE', 'URSSAF', 'Intervenants'],
      datasets: [{
        data: [
          this.opMargin,
          this.totalUrssafJe,
          this.totalPay
        ],
        backgroundColor: [
          'rgba(231, 76, 60, 1)',
          'rgba(52, 73, 94, 1)',
          'rgba(208, 216, 232, 1)'
        ]
      }]
    }
  }

  get saveObject () : { projectName: string, phases: PhaseObject[], fee: number } {
    return {
      projectName: this.projectName,
      phases: this.phases,
      fee: this.fee
    }
  }

  // LifeCycle hood
  created () {
    if (this.$route && this.$route.params && this.$route.params.phases) {
      this.importFromB64(this.$route.params.phases)
    } else {
      this.newPhase()
    }
  }

  @Watch('phases', { deep: true })
  phasesWatcher () {
    this.setUrl()
  }

  @Watch('fee')
  feeWatcher () {
    this.setUrl()
  }

  // Methods
  setUrl () {
    this.$router.push({ name: 'phases', params: { phases: utf8ToB64(JSON.stringify(this.saveObject)) } }).catch((err) => {
      if (err && err.name !== 'NavigationDuplicated') {
        throw err
      }
    })
  }

  openProjectSidebar () {
    this.isOpenProjectSidebar = true
  }

  closeProjectSidebar () {
    this.isOpenProjectSidebar = false
  }

  async save () {
    if (this.id) {
      this.$store.dispatch('updateProject', { ...this.saveObject, id: this.id })
    } else {
      this.id = await this.$store.dispatch('addProject', this.saveObject)
    }
  }

  loadProject (project:any) {
    this.projectName = project.projectName
    this.phases = project.phases
    this.fee = project.fee

    if (project.id) {
      this.id = project.id
    }
    this.closeProjectSidebar()
  }

  newPhase () {
    let newPhase = {
      id: this.phases.length + 1,
      title: '',
      price: 900,
      jeh: 450,
      nbConsultant: 1,
      margin: 55,
      nbJeh: 0,
      pay: 0,
      urssafJE: 0,
      marginJE: 0,
      urssafConsultant: 0,
      netConsultant: 0,
      netByConsultant: 0,
      pcConsultant: 0
    }
    this.phases.push(newPhase)
  }
  deleteEvent (idPhase: number) {
    if (!(this.phases.length - 1)) { // keep at least one phase
      return
    }
    let prefix = this.phases.slice(0, idPhase - 1)
    let suffix = this.phases.slice(idPhase, this.phases.length)
    let i = 1
    this.phases = prefix
    this.phases.push(...suffix)
    this.phases.forEach(function (p) {
      p.id = i
      i++
    })
    this.calculate()
    Vue.nextTick(() => {
      // calculate all the other phases update,
      // after the deleted phase is really removed (nextTick)
      Object.keys(this.$refs.refPhase).forEach((phaseCoponent: any) => {
        this.$refs.refPhase[phaseCoponent].update('margin')
      })
    })
  }
  saveEvent (phase: PhaseObject) {
    this.phases[phase.id - 1] = phase
    this.calculate()
  }
  onNewConsultant (consultant: string) {
    this.consultants.push(consultant)
  }
  onRemoveConsultant (consultant: string) {
    this.consultants = this.consultants.filter(consultantName => {
      return consultantName !== consultant
    })
  }
  calculate () {
    this.reset()
    let this_ = this
    let averageJeh = 0
    let averageMargin = 0
    let averageMarginJe = 0
    let averagePcConsultant = 0
    this.phases.forEach(function (v) {
      this_.totalPrice += v.price
      averageJeh += v.jeh
      averageMargin += v.margin
      averageMarginJe += v.marginJE
      this_.totalNbJeh += v.nbJeh
      this_.totalPay += v.pay
      this_.totalUrssafJe += v.urssafJE
      this_.totalUrssafConsultant += v.urssafConsultant
      this_.totalNetConsultant += v.netConsultant
      averagePcConsultant += v.pcConsultant
    })
    this.averageJeh = round(averageJeh / this.phases.length)
    this.averageMargin = round(averageMargin / this.phases.length)
    this.averageMarginJe = round(averageMarginJe / this.phases.length)
    this.averagePcConsultant = round(averagePcConsultant / this.phases.length)
    this.totalNetConsultant = round(this.totalNetConsultant) // round needed otherwise 24.0000000000001 sometimes
    this.totalUrssafConsultant = round(this.totalUrssafConsultant)
    this.totalUrssafJe = round(this.totalUrssafJe)
    this.opMargin = round(this.totalPrice - this.totalUrssafJe - this.totalPay)
  }

  reset () {
    this.totalPrice = 0
    this.averageJeh = 0
    this.averageMargin = 0
    this.totalNbJeh = 0
    this.totalPay = 0
    this.totalUrssafJe = 0
    this.averageMarginJe = 0
    this.totalUrssafConsultant = 0
    this.totalNetConsultant = 0
    this.averagePcConsultant = 0
  }

  exportUrl () {
    this.setUrl()
    this.url = window.location.href
    this.$copyText(this.url)
    this.openExportPopup = true
  }

  toggleExportPopup () {
    this.openExportPopup = !this.openExportPopup
  }

  private importFromB64 (b64) {
    let jsonImported = JSON.parse(b64ToUtf8(b64))
    this.loadProject(jsonImported)
  }

  importUrl () {
    let urlSplit = this.urlImport.split('/')
    if (urlSplit.length > 2 && urlSplit[urlSplit.length - 2] === 'p') {
      this.importFromB64(urlSplit[urlSplit.length - 1])
    }
  }
}
