















import { PhaseObject, TauxObject } from '../types'
import { Component, Vue } from 'vue-property-decorator'
import JehMaker from '@/components/JehMaker.vue' // @ is an alias to /src
import Taux from '@/components/Taux.vue' // @ is an alias to /src
import Sidebar from '@/components/Sidebar.vue' // @ is an alias to /src

@Component({
  components: {
    JehMaker,
    Taux,
    Sidebar
  }
})
export default class Home extends Vue {
  taux:TauxObject = new TauxObject()
  v:boolean = false

  toogleSibebar () {
    this.v = !this.v
  }
}
