



















import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class Frais extends Vue {
    @Prop() value!:number
    @Prop() totalPrice!:number

    content:number = this.value

    handleInput (e) {
      this.$emit('input', this.content)
    }
}
