



















import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class MargesDetails extends Vue {
    @Prop() opMargin!:number;
    @Prop() totalPrice!:number;
    @Prop() averageMarginJe!:number;
    @Prop() totalPay!:number;
    @Prop() totalUrssafJe!:number;

    get margeBrut (): number {
      return (this.totalPrice - this.totalPay) / this.totalPrice * 100
    }
    get margeOp (): number {
      return (this.totalUrssafJe / this.totalPrice * 100)
    }
}
