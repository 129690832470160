





























import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Sidebar extends Vue {
  @Prop() visible!:boolean

  toogleSibebar () {
    this.$emit('toogle')
  }
}
