






















import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({
  model: {
    prop: 'consultants',
    event: 'change'
  }
})
export default class Phase extends Vue {
  // Data
  consultants: string[] = []
  consultantName: string = ''

  // Lifecycle hood
  mounted () {
  }

  // Methods
  newConsultant () {
    if (this.consultantName) {
      this.consultants.push(this.consultantName)
      this.$emit('newConsultant', this.consultantName)
      this.consultantName = ''
    }
  }
  removeConsultant (consultantName) {
    this.consultants = this.consultants.filter(consultant => {
      return consultantName !== consultant
    })
    this.$emit('removeConsultant', consultantName)
  }
}
